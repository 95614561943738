<template>
  <div class="write-page-heng-container">
    <WriteStrokePage
      :strokeImage="strokeImg"
      :backgroundImageList="bgImgList"
      :flagImageObj="flagImageObj"
      :strokeImgFill="strokeImgFill"
      :strokePinyin="strokePinyin"
    />
  </div>
</template>
<script>
import WriteStrokePage from "@/components/Course/CoursePage/WriteStrokePage";

export default {
  data() {
    return {
      strokePinyin: "héngzhézhépiě ",

      strokeImg: require("@/assets/img/15-Hanzi-strokes/lesson-26-hengzhezhepie-grey.svg"),
      strokeImgFill: require("@/assets/img/15-Hanzi-strokes/lesson-26-hengzhezhepie-red.svg"),
      bgImgList: [
        {
          step: 0,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-26-hengzhezhepie/zongzi-1.svg"),
        },
        {
          step: 1,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-26-hengzhezhepie/zongzi-2.svg"),
        },
        {
          step: 2,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-26-hengzhezhepie/zongzi-3.svg"),
        },
        {
          step: 3,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-26-hengzhezhepie/zongzi-4.svg"),
        },
      ],
      flagImageObj: {
        img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-26-hengzhezhepie/zongzi-pending.svg"),
        imgActive: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-26-hengzhezhepie/zongzi-complete.svg"),
        pinyin: "héngzhézhépiě",
        tipImg: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-26-stroke-hengzhezhepie.gif"),
      },
    };
  },
  components: {
    WriteStrokePage,
  },
};
</script>
<style lang="scss" scoped>
.write-page-heng-container {
  width: 100%;
  height: 100%;
}
</style>
