<!--
 * @Author: your name
 * @Date: 2022-03-15 09:05:33
 * @LastEditTime: 2022-03-15 17:41:15
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter3\Lesson22\11_PracticePageOne_shuzhe.vue
-->
<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :strockNotFoundImage="strockNotFoundImage"
      :strockFoundImage="strockFoundImage"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-26/background-park.svg"),
      gameIndex:26,
      strokeImgList: [
         {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-26/stroke-butterfly.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-26/stroke-butterfly-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-26/stroke-duck.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-26/stroke-duck-found.svg"),
        },
       {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-26/stroke-leaf.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-26/stroke-leaf-found.svg"),
        },
       
         {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-26/stroke-lotus.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-26/stroke-lotus-found.svg"),
        },
         {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-26/stroke-pavillion.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-26/stroke-pavillion-found.svg"),
        },
      ],
      strockNotFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-26/stroke-hengzhezhepie.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-26/stroke-hengzhezhepie-found.svg"),
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>