<template>
  <div class="game-container">
    <RecognitionNumberGame
      :ObjectList="ObjectList"
      :chineseList="chineseList"
    />
  </div>
</template>

<script>
import RecognitionNumberGame from "@/components/Course/GamePage/RecognitionNumberGame";
export default {
  data() {
    return {
      ObjectList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G41-choose-abcde/lesson-25/image-1.svg"),
          number: 1,
          showObj: true,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G41-choose-abcde/lesson-25/image-2.svg"),
          number: 2,
          showObj: true,
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G41-choose-abcde/lesson-25/image-3.svg"),
          number: 1,
          showObj: true,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G41-choose-abcde/lesson-25/image-4.svg"),
          number: 2,
          showObj: true,
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G41-choose-abcde/lesson-25/image-5.svg"),
          number: 1,
          showObj: true,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G41-choose-abcde/lesson-25/image-6.svg"),
          number: 2,
          showObj: true,
        },
      ],

      chineseList: [
                {
          id: 1,
          pinying: "zhè",
          number: 1,
          hanzi: "这",
        },

        {
          id: 2,
          pinying: "nà",
          number: 2,
          hanzi: "那",
        },

      ],
    };
  },
  components: {
    RecognitionNumberGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











